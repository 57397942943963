import React, { useState } from 'react';
import LoginPage from './components/LoginPage';
import Chat from './components/Chat';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <div>
      {isLoggedIn ? <Chat /> : <LoginPage onLogin={handleLogin} />}
    </div>
  );
};

export default App;