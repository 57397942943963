import React, { useState } from 'react';

const LoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    // Perform login validation here
    if (username === 'TahzooAdmin' && password === 'Tahzoo123!!') {
      onLogin();
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <div className='login'>
      <form>
        <h2>Login</h2>
        <input
          className='form-field'
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <br />
        <input
          className='form-field'
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <button onClick={handleLogin} className="btn submit">Login</button>
      </form>
     
    </div>
  );
};

export default LoginPage;