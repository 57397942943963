import React, { useState } from 'react';
import axios from 'axios';

const Chat = () => {
  const [message, setMessage] = useState('');
  const [chatLog, setChatLog] = useState([]);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (message.trim() === '') {
      return;
    }

    // Add the user's message to the chat log
    setChatLog((prevChatLog) => [...prevChatLog, { sender: 'User', text: message }]);

    try {
      // Send the message to the API and get the response
      const response = await axios.post('/api/message', { message: message });
      /*const response = {
        data: {
          message: "Howdy Partner"
        }
      };*/

      const responseData = JSON.parse(response.data);
      const textValue = responseData.text;

      // Add the response message to the chat log
      setChatLog((prevChatLog) => [...prevChatLog, { sender: 'Brad', text: textValue }]);

      setTimeout(() => {
        const logEl = document.querySelector('.chat-log');
        logEl.scrollTop = logEl.scrollHeight;
      }, 300);

    } catch (error) {
      console.error('Error sending message:', error);
    }

    // Clear the input field
    setMessage('');
  };

  return (
    <div>
      <h2>Ask Brad a Question!</h2>
      <div className="chat-log">
        {chatLog.map((entry, index) => (
          <div key={index} className='message'>
            <strong className={entry.sender}>{entry.sender}</strong><strong>: </strong>
            {entry.text}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <div className='form-container'>
        <input type="text" value={message} onChange={handleInputChange} className='form-input' placeholder='Ask your question.'/>
        <button type="submit" className="btn form-submit">Send</button>
        </div>
        
      </form>
    </div>
  );
};

export default Chat;
